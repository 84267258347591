import type { FunctionComponent } from 'react';
import { PageLayout } from '@common/components/PageLayout';
import { FindQueryOpts } from '@/app/utils/findQueryBuilder';
import { useParams } from 'react-router-dom';
import { useAuditsExportFindQuery } from '@/modules/admin/audits/functions/auditsExportQuery';
import { MainCard } from '@common/components/mainCard';
import { LineText } from '@common/components/fragments/LineText';
import { useTranslation } from 'react-i18next';
import Status from '@common/components/statuses/Status';
import { AuditsExportSubCardFields } from '@/modules/admin/audits/components/AuditsExportSubCardFields';
import { LocalTimeDate } from '@/app/components/LocalDatetime';
import { ExportedFilesTable } from '@/modules/admin/audits/pages/ExportedFilesTable';

export interface AuditsExportShowProps {}

export const queryOpts: FindQueryOpts = {
  includes: ['export_strategy.operation'],
  select: {},
};

export const AuditsExportShow: FunctionComponent<
  AuditsExportShowProps
> = () => {
  const { auditsExportId } = useParams();
  const { record } = useAuditsExportFindQuery(auditsExportId, queryOpts, {
    staleTime: 5000,
  });

  const { t } = useTranslation();
  if (!record) return null;

  return (
    <PageLayout Title={`Export ${record.id}`}>
      <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
        <MainCard header="Info">
          {' '}
          <LineText
            label={t('activerecord.attributes.audits/export.id', {
              ns: 'rails',
            })}
            value={record.id}
          />{' '}
          <LineText
            label={t('activerecord.attributes.audits/export.created_at', {
              ns: 'rails',
            })}
            value={<LocalTimeDate date={record.parsedCreatedAt} />}
          />{' '}
          <LineText
            label={t('activerecord.attributes.audits/export.updated_at', {
              ns: 'rails',
            })}
            value={<LocalTimeDate date={record.parsedUpdatedAt} />}
          />{' '}
          <LineText
            label={t('activerecord.attributes.audits/export.status', {
              ns: 'rails',
            })}
            value={<Status statusType={record.status} />}
          />{' '}
        </MainCard>
        <MainCard header="Paramétrage">
          <AuditsExportSubCardFields strategy={record.exportStrategy} />
        </MainCard>
        <ExportedFilesTable record={record} />
      </div>
    </PageLayout>
  );
};
