import { useBackendTable } from '@/modules/common/components/table/hooks';
import Table from '@/modules/common/components/table/Table';
import { MainCard } from '@/modules/common/components/mainCard';
import { CheckBox } from '@/modules/common/components/inputs';
import { Controller, useForm } from 'react-hook-form';
import {
  ButtonSecondary,
  ButtonSecondaryMinus,
} from '@/modules/common/components/buttons';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import useLegalEntitiesSelectOptions from '@/modules/common/legalEntity/useLegalEntitiesSelectOptions';
import EmployeePayslip from '@/app/spraypaint/EmployeePayslip';
import { useTranslation } from 'react-i18next';
import { getActualPayslipPeriod } from '@/modules/common/utils/date.utils';
import {
  usePayslips,
  useYearsSelectOptions,
} from '@/modules/admin/payslip/functions';

import { PageLayout } from '@common/components/PageLayout';
import payslipAdminColumns from '../columns/PayslipAdminIndexColumns';
import {
  dateFilterOptions,
  monthsFilterOptions,
  statusFilterOptions,
} from '../constants/PayslipIndexSelectsConstants';
import { FilterFormInputs } from '../types/FormTypes';

export function PayslipAdminIndex(): JSX.Element {
  const { month, year } = getActualPayslipPeriod();
  const { t } = useTranslation();

  const defaultValues = {
    // we need to set them at false because the checkbox goes from undefined => false
    stc: undefined,
    arbitration: undefined,
    newPayslip: undefined,
    urgentTransfer: undefined,
    missing: undefined,
    status: '',
    month: month.toString(),
    year: year.toString(),
  };
  const { watch, control, reset, getValues } = useForm<FilterFormInputs>({
    defaultValues,
  });
  const watchAllField = watch();

  // watch((data, { name, type }) => console.debug({ data, name, type }));

  const areFiltersDisabled =
    !getValues('arbitration') &&
    !getValues('urgentTransfer') &&
    !getValues('missing') &&
    !getValues('newPayslip') &&
    !getValues('stc') &&
    !getValues('requestAge') &&
    !getValues('legalEntity') &&
    !getValues('year') &&
    !getValues('status') &&
    !getValues('month');

  // const navigate = useNavigate();

  const [scope, scopeKey, props] = useBackendTable<EmployeePayslip>({
    initialSorting: [{ id: 'createdAt', desc: false }],
    debounceDelay: 200,
  });

  const handleResetFilter = () => {
    reset();
  };

  const { isLoading, totalCount, payslips } = usePayslips({
    scope,
    scopeKey,
    watchAllField,
  });

  const yearFilterOptions = useYearsSelectOptions();

  return (
    <PageLayout
      Title={t('front.menu.payslips_dashboard')}
      Buttons={
        <div className="flex justify-end gap-2">
          <ButtonSecondary title="Tunnels" to="./funnels">
            Tunnels
          </ButtonSecondary>
          <ButtonSecondary title="Statistique" to="./stats">
            Statistiques
          </ButtonSecondary>
        </div>
      }
    >
      <div className="w-full flex mb-[20px] mt-[20px] items-stretch">
        <MainCard header="Période">
          {' '}
          <ControlledSelect
            name="year"
            control={control}
            className="mt-[10px]"
            placeholder="Année"
            options={yearFilterOptions}
          />{' '}
          <ControlledSelect
            name="month"
            control={control}
            placeholder="Mois"
            className="mt-[10px]"
            options={monthsFilterOptions()}
          />
          <p className="font-cabin-bold text-sm mt-6 text-gray-600">
            Ne fonctionne que si les deux filtres ont été rempli
          </p>
        </MainCard>{' '}
        <MainCard
          header={{
            title: 'Filtres',
            actionButtons: (
              <ButtonSecondaryMinus
                disabled={areFiltersDisabled}
                onClick={handleResetFilter}
              >
                {t('front.employeePayslip.dashboard.button.resetFilter')}
              </ButtonSecondaryMinus>
            ),
          }}
          childrenClassName="flex"
          className="ml-[20px] grow"
        >
          <div className="flex flex-col flex-2">
            <div className="flex items-center">
              <ControlledSelect
                name="legalEntity"
                control={control}
                labelClassName="flex-1 text-xs mb-0 mr-4"
                className="ml-[10px] "
                label="Entité"
                isMulti
                options={useLegalEntitiesSelectOptions()}
              />
            </div>
            <div className="flex items-center mt-[10px]">
              <ControlledSelect
                name="status"
                isMulti
                control={control}
                labelClassName="flex-1  mb-0 mr-4"
                className="ml-[10px]"
                label="Statut"
                options={statusFilterOptions()}
              />
            </div>
            <div className="flex items-center mt-[10px]">
              <ControlledSelect
                labelClassName="flex-1  mb-0 mr-4"
                label="Ancienneté de la demande"
                className="ml-[10px]"
                name="requestAge"
                control={control}
                options={dateFilterOptions}
              />
            </div>
          </div>
          <div className="flex grow flex-col ml-[80px]">
            <Controller
              name="stc"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckBox
                  onChange={(e) => {
                    onChange(e.target.checked || undefined);
                  }}
                  checked={!!value}
                  label="STC"
                  id="stc"
                />
              )}
            />{' '}
            <Controller
              name="newPayslip"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckBox
                  onChange={(e) => {
                    onChange(e.target.checked || undefined);
                  }}
                  checked={!!value}
                  label="Nouveau"
                  id="nouveau"
                  className="mt-[5px]"
                />
              )}
            />{' '}
            <Controller
              name="missing"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckBox
                  onChange={(e) => {
                    onChange(e.target.checked || undefined);
                  }}
                  checked={!!value}
                  label="Absent"
                  id="missing"
                  className="mt-[5px]"
                />
              )}
            />{' '}
            <Controller
              name="urgentTransfer"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckBox
                  onChange={(e) => {
                    onChange(e.target.checked || undefined);
                  }}
                  checked={!!value}
                  label="Virement urgent"
                  id="transfert"
                  className="mt-[5px]"
                />
              )}
            />{' '}
            <Controller
              name="arbitration"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckBox
                  onChange={(e) => {
                    onChange(e.target.checked || undefined);
                  }}
                  checked={!!value}
                  label="Arbitrage"
                  id="arbitrage"
                  className="mt-[5px]"
                />
              )}
            />
          </div>
        </MainCard>
      </div>
      <Table
        isLoading={isLoading}
        totalCount={totalCount}
        data={payslips || []}
        reactTableProps={props}
        columns={payslipAdminColumns}
        availablePageSizes={[
          { value: '20', label: '20' },
          { value: '50', label: '50' },
          { value: '100', label: '100' },
        ]}
        defaultPageSize={50}
      />{' '}
    </PageLayout>
  );
}
