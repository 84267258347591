// subforms/index.ts
import { useTranslation } from 'react-i18next';
import {
  ExtractExportStrategyType,
  SubformRecord,
} from '@/modules/admin/audits/components/submodules/types';
import { DueInvoicesExportSubModule } from '@/modules/admin/audits/components/submodules/AuditsExportsDueINvoicesExportSubmodule';
import { AuditsExportsProvisionExportSubmodule } from '@/modules/admin/audits/components/submodules/AuditsExportsProvisionExportSubmodule';
import { AllInvoiceSubModule } from './AuditsExportAllInvoiceSubmodule';

export const subformModules = [
  // Simply add your subform modules here
  AllInvoiceSubModule,
  DueInvoicesExportSubModule,
  AuditsExportsProvisionExportSubmodule,
] as const;

export type SubformModules = typeof subformModules;

export function useSubformOptions() {
  const { t } = useTranslation();
  return subformModules.map((module) => {
    const key = module.jsonApiType.split('-').pop();
    return {
      value: module.jsonApiType,
      label:
        (module as unknown as { label: string }).label ||
        t(`activerecord.models.audits/exports/${key}`, {
          ns: 'rails',
        }),
    };
  });
}

export const getSubModuleByJsonApiType = (jsonApiType: string | null) =>
  subformModules.find((module) => module.jsonApiType === jsonApiType);

export type ExportStrategyType =
  | { key: null | string; params: object }
  | ExtractExportStrategyType<SubformModules[number]>;

export function createExportStrategy(data: ExportStrategyType): SubformRecord {
  const module = getSubModuleByJsonApiType(data.key);
  if (!data.key || !module) {
    throw new Error(`Subform module not found for key ${data.key}`);
  }

  return module.createExportStrategy(data.params as any); // eslint-disable-line @typescript-eslint/no-explicit-any
}
