// api/AuditsExport.ts
import { Model, Attr, HasOne, Link } from 'spraypaint';
import AuditsExportsAllInvoice from '@spraypaint/audits/exports/AuditsExportsAllInvoice';
import AuditsExportsDueInvoicesExport from '@spraypaint/audits/exports/AuditsExportsDueInvoicesExport';
import { parseIso8601 } from '@/app/utils/dateUtils';
import AuditsExportsProvisionExport from '@spraypaint/audits/exports/AuditsExportsProvisionExport';
import ApplicationRecord from '../ApplicationRecord';

export type StatusEnum =
  | 'created'
  | 'scheduled'
  | 'running'
  | 'success'
  | 'error';

export type ExportedFile = {
  filename: string;
  url: string;
  content_type: string;
  bytesize: number;
  human_size: string;
};

@Model()
class AuditsExport extends ApplicationRecord {
  static jsonapiType = 'audits-exports';

  @Link() download!: string;

  // Attributes
  @Attr({ persist: false }) status!: StatusEnum;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) filename!: string;

  // Extra-attributes
  @Attr({ persist: false }) exportedFiles!: ExportedFile[];

  // Relationships
  @HasOne({ name: 'export_strategy' })
  exportStrategy!:
    | AuditsExportsAllInvoice
    | AuditsExportsDueInvoicesExport
    | AuditsExportsProvisionExport;

  // parse from iso8601 to Date
  get parsedCreatedAt(): Date | null {
    return parseIso8601(this.createdAt);
  }

  get parsedUpdatedAt(): Date | null {
    return parseIso8601(this.updatedAt);
  }
}

export default AuditsExport;
