import { PageLayout } from '@/modules/common/components/PageLayout';
import type { FunctionComponent } from 'react';
import { MainCard } from '@common/components/mainCard';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import AuditsExport from '@spraypaint/audits/AuditsExport';
import {
  AuditsExportForm,
  AuditsExportFormValues,
} from '@/modules/admin/audits/components/AuditsExportForm';
import { createExportStrategy } from '@/modules/admin/audits/components/submodules';

export const AuditsExportNew: FunctionComponent = () => {
  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    mutationFn: async (data: AuditsExportFormValues) => {
      const es = createExportStrategy(data.exportStrategy);

      const ae = new AuditsExport({
        ...data,
        exportStrategy: es,
      });

      return ae.saveOrThrow({ with: 'exportStrategy' });
    },
    onSuccess: (data) => {
      navigate(`../${data.id}`);
    },
  });

  return (
    <PageLayout Title="Nouvel Export">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MainCard
          header="Générer un export"
          Content={AuditsExportForm}
          contentProps={{ onSubmit: mutateAsync }}
        />
      </div>
    </PageLayout>
  );
};
