import type { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledSelect } from '@common/components/inputs/controlledInput/ControlledSelect';
import useLegalEntitiesSelectOptions from '@common/legalEntity/useLegalEntitiesSelectOptions';
import { ButtonPrimary } from '@common/components/buttons';
import { useMutation } from '@tanstack/react-query';
import PayslipFunnelAutocompletion from '@spraypaint/PayslipFunnelAutocompletion';
import { useNavigate } from 'react-router-dom';

type AutocompletionFormState = {
  payslipPeriod: string | null | undefined;
  legalEntityId: string | null | undefined;
};

// Return the end of month from 10 days ago
function currentPayslipPeriod(): string {
  const date = new Date();
  date.setDate(date.getDate() - 10);
  const endOfMonth = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0),
  );
  return endOfMonth.toISOString().split('T')[0];
}

type PayslipPeriodOptions = Array<{
  label: string;
  value: string;
}>;

// from 3 end of month before currentPayslipPeriod to 3 months after
function buildPayslipPeriodOptions(): PayslipPeriodOptions {
  const currentPayslipPeriodDate = new Date(currentPayslipPeriod());
  const currentMonth = currentPayslipPeriodDate.getUTCMonth();
  const currentYear = currentPayslipPeriodDate.getUTCFullYear();
  return [-2, -1, 0, 1, 2].map((monthOffset) => {
    const periodDate = new Date(
      Date.UTC(currentYear, currentMonth + monthOffset + 1, 0),
    );

    const periodMonth = periodDate.getUTCMonth();
    const periodYear = periodDate.getUTCFullYear();

    return {
      label: `${periodMonth + 1}/${periodYear}`,
      value: new Date(periodDate).toISOString().split('T')[0],
    };
  });
}

export const PayslipFunnelsAutocompletionForm: FunctionComponent = () => {
  const navigate = useNavigate();

  const legalEntities = useLegalEntitiesSelectOptions();

  const { control, handleSubmit } = useForm<AutocompletionFormState>({
    defaultValues: {
      payslipPeriod: currentPayslipPeriod(),
      legalEntityId: null,
    },
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (data: AutocompletionFormState) => {
      const object = new PayslipFunnelAutocompletion(data);
      return object.saveOrThrow();
    },
    onSuccess: (data) => {
      console.log(data);
      navigate(`/v2/admin/operations/${data.operationId}`);
    },
  });

  const confirmedMutation = (data: AutocompletionFormState) => {
    const confirmed = window.confirm(
      "Cette opération n'est pas annulable. Souhaitez-vous la lancer ?",
    );
    if (!confirmed) return;
    mutateAsync(data);
  };

  return (
    <form
      onSubmit={handleSubmit(confirmedMutation)}
      className="flex flex-col gap-4 items-start"
    >
      <ControlledSelect
        control={control}
        label="Période de paie"
        options={buildPayslipPeriodOptions()}
        name="payslipPeriod"
        required
      />
      <ControlledSelect
        control={control}
        label="Entité"
        options={legalEntities}
        name="legalEntityId"
        required
      />

      <ButtonPrimary type="submit" isLoading={isLoading}>
        Enregistrer
      </ButtonPrimary>
    </form>
  );
};
