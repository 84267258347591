import React, { useRef, useEffect, ReactNode } from 'react';
import { useEnvBannerContext } from '@/app/components/EnvBanner';

interface MirroredScrollWrapperProps {
  /** Content that needs horizontal mirroring */
  children: ReactNode;
  /** Additional Tailwind / CSS classes for the main content container */
  className?: string;
  /**
   * Additional classes for the top scrollbar container.
   * Defaults to a sticky pinned top bar with white background and higher z-index.
   */
  topWrapperClasses?: string;
}

const headerTopValue = 80;

/**
 * MirroredScrollWrapper
 *
 * 1) Renders a top “fake” scrollbar that syncs horizontally with the real content below.
 * 2) Does not constrain vertical height, so the content is free to grow vertically.
 * 3) By default, the top scrollbar is sticky at the top (`sticky top-0`).
 *
 * Usage:
 *
 * <MirroredScrollWrapper>
 *   <YourLargeOrWideContent />
 * </MirroredScrollWrapper>
 */
export default function MirroredScrollWrapper({
  children,
  className = '',
  topWrapperClasses = 'sticky top-0 bg-white z-10 top-16',
}: MirroredScrollWrapperProps) {
  const topScrollRef = useRef<HTMLDivElement>(null);
  const topDummyRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { hidden } = useEnvBannerContext();

  const envTopValue = hidden ? 0 : 40;

  useEffect(() => {
    const syncScrollbarWidth = () => {
      if (topDummyRef.current && contentRef.current) {
        topDummyRef.current.style.width = `${contentRef.current.scrollWidth}px`;
      }
    };

    const handleTopScroll = () => {
      if (topScrollRef.current && contentRef.current) {
        contentRef.current.scrollLeft = topScrollRef.current.scrollLeft;
      }
    };

    const handleBottomScroll = () => {
      if (topScrollRef.current && contentRef.current) {
        topScrollRef.current.scrollLeft = contentRef.current.scrollLeft;
      }
    };

    const topEl = topScrollRef.current;
    const bottomEl = contentRef.current;

    if (topEl && bottomEl) {
      topEl.addEventListener('scroll', handleTopScroll, { passive: true });
      bottomEl.addEventListener('scroll', handleBottomScroll, {
        passive: true,
      });
    }

    window.addEventListener('resize', syncScrollbarWidth);

    // Initial calculation
    syncScrollbarWidth();

    return () => {
      window.removeEventListener('resize', syncScrollbarWidth);
      if (topEl && bottomEl) {
        topEl.removeEventListener('scroll', handleTopScroll);
        bottomEl.removeEventListener('scroll', handleBottomScroll);
      }
    };
  }, []);

  return (
    <div className="relative">
      {/* Top horizontal scrollbar */}
      <div
        ref={topScrollRef}
        className={`overflow-x-auto overflow-y-hidden ${topWrapperClasses}`}
        style={{ top: `${envTopValue + headerTopValue}px` }}
      >
        {/* A minimal-height div to create the horizontal track */}
        <div ref={topDummyRef} className="h-px" />
      </div>

      {/* Actual content (with real horizontal scrolling) */}
      <div ref={contentRef} className={`overflow-x-auto ${className}`}>
        {children}
      </div>
    </div>
  );
}
