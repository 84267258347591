import { useState, useEffect } from 'react';
import { CurrencyIsoCode } from '../utils/type.utils';

export const currenciesCodes: {
  [key in CurrencyIsoCode]: { symbol: string; locale: string };
} = {
  EUR: {
    symbol: '€',
    locale: 'fr-FR',
  },
  USD: {
    symbol: 'USD',
    locale: 'en-US',
  },
  GBP: {
    symbol: '£',
    locale: 'en-GB',
  },
  CAD: {
    symbol: 'CAD',
    locale: 'en-CA',
  },
  JPY: {
    symbol: '¥',
    locale: 'ja-JP',
  },
  HKD: {
    symbol: 'HKD',
    locale: 'en-HK',
  },
  AUD: {
    symbol: 'AUD',
    locale: 'en-AU',
  },
  CHF: {
    symbol: 'CHF',
    locale: 'fr-CH',
  },
  SGD: {
    symbol: 'SGD',
    locale: 'zh-SG',
  },
  SEK: {
    symbol: 'SE kr',
    locale: 'sv-SE',
  },
  NOK: {
    symbol: 'NO kr',
    locale: 'no-NO',
  },
  NZD: {
    symbol: 'NZD',
    locale: 'en-NZ',
  },
  MXN: {
    symbol: 'MXN',
    locale: 'es-MX',
  },
  DKK: {
    symbol: 'DK kr',
    locale: 'da-DK',
  },
  PLN: {
    symbol: 'zł',
    locale: 'pl-PL',
  },
  ZAR: {
    symbol: 'R',
    locale: 'en-ZA',
  },
};

const useCurrencyCode = (code: CurrencyIsoCode | null) => {
  const [currencySymbol, setCurrencySymbol] = useState<string>('');
  const [currencyLocale, setCurrencyLocale] = useState<string>('');
  useEffect(() => {
    setCurrencySymbol((code && currenciesCodes[code]?.symbol) || code || '');
    setCurrencyLocale((code && currenciesCodes[code]?.locale) || code || '');
  }, [code]);

  return { currencySymbol, currencyLocale };
};

export default useCurrencyCode;
