import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import { PayslipAdminStats } from '@/modules/admin/payslip/pages/PayslipAdminStats';
import { PayslipAdminFunnels } from '@/modules/admin/payslip/pages/PayslipAdminFunnels';
import { PayslipAdminIndex } from './pages';

export const mountPayslipAdminRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.menu.payslips_dashboard') },
    children: [
      index({ index: true, Component: PayslipAdminIndex }),
      member({
        path: 'stats',
        Component: PayslipAdminStats,
        handle: { crumb: () => 'Statistiques' },
      }),
      member({
        path: 'funnels',
        Component: PayslipAdminFunnels,
        handle: { crumb: () => 'Tunnels de salaire' },
      }),
    ],
  }),
];
