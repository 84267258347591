import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { ButtonPrimary } from '@common/components/buttons';
import {
  ExportStrategyType,
  getSubModuleByJsonApiType,
  useSubformOptions,
} from '@/modules/admin/audits/components/submodules';
import { AuditsExportSubform } from '@/modules/admin/audits/components/AuditsExportSubform';
import { ControlledSelect } from '@common/components/inputs/controlledInput/ControlledSelect';
import { ApiValidationError } from '@spraypaint/ApiValidationError';
import AuditsExport from '@spraypaint/audits/AuditsExport';
import { toastValidationError } from '@/app/utils/toastValidationErrors';

export const AuditsExportForm: FunctionComponent<AuditsExportFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm<AuditsExportFormValues>({
    defaultValues: {
      exportStrategy: {
        key: null,
        params: {},
      },
    },
  });

  const options = useSubformOptions();
  const selectedKey = useWatch({ name: 'exportStrategy.key', control });

  // Effect to reset params when key changes
  useEffect(() => {
    console.log('Effect', selectedKey);

    const module = getSubModuleByJsonApiType(selectedKey ?? null);
    if (module) {
      setValue('exportStrategy.params', module.defaultValues); // Type assertion may be needed
    } else {
      // If module not found, reset to default
      setValue('exportStrategy.params', {});
    }
  }, [selectedKey, setValue]);

  const handleOnSubmit = async (data: AuditsExportFormValues) => {
    try {
      await onSubmit(data);
    } catch (error) {
      if (ApiValidationError.isInstance(error, AuditsExport)) {
        toastValidationError(error.record, {
          ignoreErrorIf: (e) => e.attribute === 'export_strategy',
        });
        return;
      }
      throw error;
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <ControlledSelect
        control={control}
        name="exportStrategy.key"
        options={options}
        label={t('activerecord.attributes.audits/export.export_strategy', {
          ns: 'rails',
        })}
        className="mb-8"
        required
      />{' '}
      <AuditsExportSubform control={control} selectedKey={selectedKey} />{' '}
      <ButtonPrimary
        isLoading={isSubmitting}
        className="mt-[51px]"
        type="submit"
      >
        {t('default.export')}
      </ButtonPrimary>
    </form>
  );
};

export type AuditsExportFormValues = {
  exportStrategy: ExportStrategyType;
};

export interface AuditsExportFormProps {
  onSubmit: (data: AuditsExportFormValues) => Promise<unknown>;
}
