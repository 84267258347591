import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@common/components/table/Table';
import { ButtonSecondaryMinus } from '@common/components/buttons';
import { PersistedSpraypaintRecord } from 'spraypaint';
import AuditsExport, { ExportedFile } from '@spraypaint/audits/AuditsExport';
import { createColumnHelper } from '@tanstack/react-table';
import { useAuditsExportFindQuery } from '@/modules/admin/audits/functions/auditsExportQuery';
import { queryOpts } from '@/modules/admin/audits/pages/AuditsExportShow';

interface ExportedFilesTableProps {
  record: PersistedSpraypaintRecord<AuditsExport>;
}

type ExportedFilesHierachy = {
  name: string;
  human_size: string | null;
  url: string | null;
  children: ExportedFilesHierachy[] | undefined;
};

const ch = createColumnHelper<ExportedFilesHierachy>();
const columns = [
  ch.accessor('name', {
    header: 'Nom',
    cell: ({ row, getValue }) => {
      const spacers = [];
      for (let i = 0; i < row.depth; i++) {
        spacers.push(<span className="ml-4" />);
      }

      return (
        <span>
          {spacers}
          {getValue()}
        </span>
      );
    },
  }),
  ch.accessor('human_size', { header: 'Taille' }),
];

export function buildExportedFilesHierarchy(
  exportedFiles: ExportedFile[],
): ExportedFilesHierachy[] {
  // Temporary structure for building hierarchy
  interface TempNode {
    name: string;
    url: string | null;
    human_size: string | null;
    children: Map<string, TempNode>;
    isFile: boolean;
  }

  const root: Map<string, TempNode> = new Map();

  // Helper function to insert a file into the hierarchy map
  function insertFile(
    parts: string[],
    file: ExportedFile,
    currentMap: Map<string, TempNode>,
  ) {
    const [current, ...rest] = parts;
    if (!current) return; // Safety check

    // If node doesn't exist, create a directory-like node by default
    if (!currentMap.has(current)) {
      currentMap.set(current, {
        name: current,
        url: null,
        human_size: null,
        children: new Map(),
        isFile: false,
      });
    }

    const node = currentMap.get(current)!;

    // If we still have parts left, go deeper
    if (rest.length > 0) {
      // Still traversing directories
      insertFile(rest, file, node.children);
    } else {
      // Leaf node: file
      // Convert this node to a file node
      node.url = file.url;
      node.human_size = file.human_size;
      node.isFile = true;
      node.children = new Map(); // A file node has no children
    }
  }

  for (const file of exportedFiles) {
    // Remove leading slash and split path
    const pathParts = file.filename.replace(/^\/+/, '').split('/');
    insertFile(pathParts, file, root);
  }

  // Convert Map-based structure to the final array structure
  function mapNodeToHierarchy(node: TempNode): ExportedFilesHierachy {
    let childrenArray: ExportedFilesHierachy[] | undefined;
    if (!node.isFile && node.children.size > 0) {
      childrenArray = Array.from(node.children.values()).map(
        mapNodeToHierarchy,
      );
    }

    // For directories or non-leaf nodes, we keep url/human_size as null
    // For files, we already assigned values
    return {
      name: node.name,
      url: node.isFile ? node.url : null,
      human_size: node.isFile ? node.human_size : null,
      children: childrenArray,
    };
  }

  return Array.from(root.values()).map(mapNodeToHierarchy);
}

export const ExportedFilesTable: FunctionComponent<ExportedFilesTableProps> = ({
  record,
}) => {
  const { t } = useTranslation();
  const {
    filename,
    links: { download },
  } = record;

  const { record: filesRecord, isLoading } = useAuditsExportFindQuery(
    record.id,
    {
      selectExtra: {
        [AuditsExport.jsonapiType]: ['exported_files'],
      },
    },
    {
      staleTime: 30000,
    },
  );

  const exportedFiles = filesRecord?.exportedFiles || [];

  const hierarchy = buildExportedFilesHierarchy(exportedFiles);

  return (
    <Table
      title={t('activerecord.attributes.audits/export.exported_files', {
        ns: 'rails',
      })}
      isLoading={isLoading}
      tableInformations={
        <div className="py-2">
          <ButtonSecondaryMinus
            href={download}
            className="px-[35px]"
            download={filename}
          >
            {t('default.button.download', { ns: 'rails' })}
          </ButtonSecondaryMinus>
        </div>
      }
      className="col-span-2"
      data={hierarchy}
      getSubRows={(file) => file.children}
      columns={columns}
      disabledPagination
      reactTableProps={{ enableColumnFilters: false }}
      onRowClick={(file, row) => () => {
        if (file.url) {
          return window.open(file.url, '_blank');
        }
        row.toggleExpanded();
      }}
    />
  );
};
