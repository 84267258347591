import { SpraypaintBase } from 'spraypaint';
import { toast, ToastOptions } from 'react-toastify';
import {
  IValidationError,
  ValidationErrors,
} from 'spraypaint/lib-esm/validation-errors';

type ToastValidationErrorOptions = {
  ignoreErrorIf?: (error: IValidationError<SpraypaintBase>) => boolean;
  toastOptions?: ToastOptions;
};

function toastRecordErrors(
  errors: ValidationErrors<SpraypaintBase>,
  options: ToastValidationErrorOptions,
) {
  Object.keys(errors).forEach((e) => {
    const error = errors[e];
    if (!error) return;
    if (options.ignoreErrorIf && options.ignoreErrorIf(error)) return;

    toast.error(error?.fullMessage, {
      autoClose: 5000,
      ...(options.toastOptions ?? {}),
    });
  });
}

export function toastValidationError(
  record: SpraypaintBase,
  options: ToastValidationErrorOptions = {},
  toasted: SpraypaintBase[] = [],
) {
  const { relationships, errors } = record;

  // ignore records already toasted
  if (toasted.includes(record)) return;

  // toast self
  toastRecordErrors(errors, options);

  // mutate toasted to add record
  toasted.push(record);

  // toast relationships
  Object.keys(relationships).forEach((e) => {
    const rel = relationships[e];

    // check if array
    if (Array.isArray(rel)) {
      rel.forEach((r) => toastValidationError(r, options, toasted));
    } else {
      toastValidationError(rel, options, toasted);
    }
  });
}
