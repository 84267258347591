import { Model, Attr } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class PayslipFunnelAutocompletion extends ApplicationRecord {
  static jsonapiType = 'payslip_funnel_autocompletions';

  // Attributes
  @Attr() payslipPeriod!: unknown;
  @Attr() legalEntityId!: number;
  @Attr({ persist: false }) operationId!: number;

  // Extra-attributes

  // Relationships
}

export default PayslipFunnelAutocompletion;
