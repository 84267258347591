import type { FunctionComponent } from 'react';
import { PageLayout } from '@common/components/PageLayout';
import { MainCard } from '@common/components/mainCard';
import { PayslipFunnelsAutocompletionForm } from '@/modules/admin/payslip/components/PayslipFunnelsAutocompletionForm';

export const PayslipAdminFunnels: FunctionComponent = ({}) => (
  <PageLayout Title="Tunnels de salaire">
    <MainCard header="Fermer les tunnels">
      <PayslipFunnelsAutocompletionForm />
    </MainCard>
  </PageLayout>
);
