import type { FunctionComponent } from 'react';
import { PageLayout } from '@common/components/PageLayout';
import { getActualPayslipPeriod, getMonthName } from '@common/utils/date.utils';
import { useTranslation } from 'react-i18next';
import { usePayslipStats } from '@/modules/admin/payslip/functions';
import Table from '@common/components/table/Table';
import {
  managerStatColumns,
  requestAgeStatColumns,
  statusStatColumns,
} from '@/modules/admin/payslip/columns/PayslipAdminStatColumns';

export interface PayslipAdminStatsProps {}

export const PayslipAdminStats: FunctionComponent<
  PayslipAdminStatsProps
> = ({}) => {
  const { month, year } = getActualPayslipPeriod();
  const { t } = useTranslation();

  const {
    isLoading: statLoader,
    managerStats,
    requestAgeStats,
    statusStats,
  } = usePayslipStats({
    year,
    month,
  });

  const statTitle = t('front.employeePayslip.dashboard.statistic.header', {
    month: getMonthName(year, month),
    year: year.toString(),
  });

  return (
    <PageLayout Title={statTitle}>
      <Table
        isLoading={statLoader}
        title="Status stat"
        data={statusStats}
        columns={statusStatColumns}
        disabledPagination
      />
      <Table
        data={requestAgeStats}
        isLoading={statLoader}
        title="Ancienneté de la demande statistiques"
        className="mt-[20px]"
        columns={requestAgeStatColumns}
        disabledPagination
      />
      <Table
        data={managerStats}
        isLoading={statLoader}
        title="Manager statistiques *WIP*"
        className="mt-[20px]"
        columns={managerStatColumns}
        disabledPagination
      />
    </PageLayout>
  );
};
